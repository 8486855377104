<template>
  <header-app />
  <main>
    <router-view />
  </main>
  <some-info />
</template>

<script>
import HeaderApp from "@/components/HeaderApp.vue";
import SomeInfo from "@/components/SomeInfo.vue";

export default {
  components: {
    HeaderApp,
    SomeInfo,
  },
};
</script>

<style></style>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700&display=swap");

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #000;
  display: grid;
  grid-template-columns: 70px 1fr auto;
  min-height: 100vh;
  main {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  #app {
    grid-template-columns: 1fr;
  }
}
</style>
