<template>
  <section class="profile">
    <div class="profile-card">
      <div class="profile-img">
        <img
          src="@/assets/img/Bassem-Zaki.png"
          alt="Bassem Zaki"
          draggable="true"
        />
      </div>
      <h2>Bassem zaki</h2>
      <h3><span>Senior</span>Front-End Developer</h3>
      <ul>
        <li>
          <p>
            <span class="icon-paperplane"></span>
            bassemzaki1@hotmail.com
          </p>
        </li>
        <li>
          <p><span class="icon-smartphone"></span> 01288226986</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "SomeInfo",
};
</script>

<style lang="scss" scoped>
.profile {
  background-color: #f9fafb;
  position: relative;
  padding: 50px 15px;
  width: 305px;
  grid-area: info;
  .profile-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 70px;
    .profile-img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 100px;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
    h2 {
      font-weight: 400;
      margin: 20px 0 6px;
    }
    h3 {
      font-weight: 300;
      text-align: center;
      span {
        display: block;
      }
    }
    ul {
      margin-top: 20px;
      li {
        border: 2px solid #f0f0f0;
        padding: 15px 10px;
        border-radius: 6px;
        margin-bottom: 10px;
        p {
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          span {
            font-size: 1.5rem;
            margin-right: 8px;
            background-color: #5843be;
            padding: 5px;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            color: #fff;
          }
          .icon-smartphone {
            background-color: #fe7066;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .profile {
    display: none;
  }
}
</style>
