<template>
  <header>
    <div class="logo">
      <span>B</span>
    </div>
    <div ref="navBtn" class="nav-btn" @click="openNav">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <nav ref="nav" class="nav-list">
      <ul>
        <li title="Personal Info">
          <router-link to="/" exact>
            <span class="link icon-user-o"></span>
          </router-link>
        </li>
        <li>
          <router-link to="/education-courses"
            ><span class="link icon-education"></span
          ></router-link>
        </li>
        <li>
          <router-link to="/skills"
            ><span class="link icon-code"></span
          ></router-link>
        </li>
        <li>
          <router-link to="/portfolio"
            ><div class="portfolio-icon">
              <span></span><span></span><span></span><span></span>
            </div>
          </router-link>
        </li>
        <li>
          <router-link to="/SoftSkillsHobbies"
            ><span class="link icon-camera-outline"></span
          ></router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "HeaderApp",
  methods: {
    openNav() {
      this.$refs.navBtn.classList.toggle("active");
      this.$refs.nav.classList.toggle("active");
    },
  },
  mounted() {
    const html = document.querySelector("html");
    const navBtn = document.querySelector(".nav-btn");
    const navList = document.querySelector(".nav-list");

    html.addEventListener("click", function (e) {
      const navBtnRemove = e.target.closest(".nav-btn");
      if (!navBtnRemove && e.target !== navList) {
        navBtn.classList.remove("active");
        navList.classList.remove("active");
      }
    });
  },
};
</script>

<style scoped lang="scss">
header {
  padding: 50px 15px;
  width: 70px;
  background-color: #f9fafb;
  .logo {
    position: fixed;
    top: 40px;
    left: 10px;
    z-index: 999;
    margin: 0 0 20px 5px;
    span {
      width: 40px;
      height: 40px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fa5f1d;
      color: #fff;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }
  .nav-btn {
    display: none;
  }

  nav {
    background-color: #f9fafb;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 900;
    padding: 100px 10px 10px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      li {
        margin: 16px 0;
        a {
          display: block;
          text-decoration: none;
          color: #b6bbc5;
          width: 50px;
          height: 50px;
          border-radius: 80px;
          box-shadow: -3px -3px 7px #ffffff73,
            3px 3px 5px rgba(94, 104, 121, 0.288);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.4rem;
          &.router-link-active {
            box-shadow: inset -3px -3px 7px #ffffff73,
              inset 3px 3px 5px rgba(94, 104, 121, 0.288);
            color: #000;
          }
          .portfolio-icon {
            width: 40px;
            height: 40px;
            display: flex;
            flex-wrap: wrap;
            border-radius: 50px;
            justify-content: center;
            align-items: center;
            padding: 6px;
            span {
              display: block;
              width: 8px;
              height: 8px;
              margin: 0 2px;
              border-radius: 2px;
              background-color: #b6bbc5;
            }
          }
          &.router-link-active .portfolio-icon {
            span:nth-child(1) {
              background-color: #03045e;
            }
            span:nth-child(2) {
              background-color: #0077b6;
            }
            span:nth-child(3) {
              background-color: #00b4d8;
            }
            span:nth-child(4) {
              background-color: #ade8f4;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  header {
    padding: 8px 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 5555;
    background-color: #f9fafb;
    box-shadow: 0 0 6px 0px #ccc;
    .logo {
      position: static;
      margin: 0;
    }
    .nav-btn {
      display: flex;
      background-color: transparent;
      border: 0;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;
      span {
        display: block;
        background-color: #000;
        width: 24px;
        height: 3px;
        margin: 3px 0;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
        &:nth-child(2) {
          width: 18px;
          transition: all 0.2s ease-in-out;
        }
      }

      &.active {
        height: 27px;
        span {
          background-color: rgb(165, 19, 19);
          margin: -1px auto;
          border-radius: 10px;
          &:first-child {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            display: none;
          }
          &:last-child {
            transform: rotate(-45deg);
          }
        }
      }
    }
    nav {
      left: -100%;
      transition: all 0.3s ease-in-out;
      &.active {
        left: 0;
      }
    }
  }
}
</style>
