<template>
  <div class="home">
    <div class="g-container">
      <h2>Hello, Welcome to my Resume</h2>
      <h1>I'm Bassem Zaki</h1>
      <p>Senior Front-End Developer</p>
      <ul class="person-info">
        <li>
          <span class="icon-user-tie" title="Name"></span> Bassem Mohamed
          Elsayed Zaki
        </li>
        <li>
          <span class="icon-birthday-cake" title="Date of Birth"></span>
          30/10/1991
        </li>
        <li>
          <span class="icon-military_tech" title="Military status"></span>
          Completed
        </li>
        <li>
          <span class="icon-direction" title="Address"></span> Shoubra, Cairo,
          Egypt
        </li>
        <li>
          <span class="icon-paperplane" title="E-mail"></span>
          bassemzaki1@hotmail.com
        </li>
        <li><span class="icon-smartphone" title="Phone"></span> 01288226986</li>
      </ul>
      <div class="experience">
        <p>
          I have more than three years of experience as a Frontend Web Developer
          with extensive experience in building high-quality websites in
          Javascript with vue.js and web standards. I focus on delivering
          top-quality frontend interfaces, performance, and structure
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.home {
  padding: 50px 5px;
  .g-container {
    h2,
    h1,
    p {
      text-align: center;
    }
    h2 {
      font-weight: 400;
    }
    p {
      margin-bottom: 30px;
      font-size: 1.1rem;
    }
    .person-info {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
      grid-template-rows: 160px 180px;
      grid-auto-rows: 160px 180px;
      gap: 20px;
      justify-items: center;
      li {
        background: #453;
        border-radius: 5px;
        padding: 10px;
        color: #fff;
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: auto 1fr;
        grid-auto-flow: dense;
        span {
          font-size: 2rem;
          padding: 0 5px 0 0;
        }
        &:nth-child(1) {
          background-color: #5843be;
        }
        &:nth-child(2) {
          background-color: #ffede6;
          color: #fa5f1c;
        }
        &:nth-child(3) {
          background-color: #f6f4ff;
          color: #5843be;
        }
        &:nth-child(4) {
          background-color: #ffa067;
        }
        &:nth-child(5) {
          background-color: #fe7066;
        }
        &:nth-child(6) {
          background-color: #ade8f4;
          color: #03045e;
        }
      }
      @media (min-width: 992px) {
        li {
          padding: 10px 20px;
        }
      }
    }
    .experience {
      margin-top: 40px;
      p {
        border-radius: 8px;
        padding: 30px 10px;
        background-color: #f02349;
        color: #fff;
        font-size: 1.2rem;
      }
    }
    @media (max-width: 992px) {
      .person-info {
        gap: 15px;
        justify-items: stretch;
        li {
          &:nth-child(5) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
</style>
